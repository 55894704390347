
import type { IPhoneNumber } from '@/components//BsPhoneNumberDropdown/BsPhoneNumberDropdown.interfaces'
import { CountryAddressDetails } from '@/types'
import { PropType, ref, computed, onMounted } from 'vue'
import { UserClaims } from '@okta/okta-auth-js'
import { updateUser } from '@/api/UserApi'
import { injectCountryService } from '@/providers/CountryService/CountryService.utils'
import { ICountry } from '@/providers/CountryService/CountryService.interfaces'

export default {
  props: {
    oktaUser: {
      type: Object as PropType<UserClaims>,
      required: true
    }
  },
  setup(props: any) {
    const countryService = injectCountryService()

    const firstName = computed(() => props.oktaUser.firstName ?? '')
    const lastName = computed(() => props.oktaUser.lastName ?? '')
    const phoneNumber = computed<IPhoneNumber>(() => ({
      dialingCode: props.oktaUser.dialingCode ?? '',
      number: props.oktaUser.phoneNumber ?? '',
      countryCode: props.oktaUser.countryCode ?? ''
    }))
    const countryCode = computed(() => props.oktaUser.countryCode ?? '')
    const email = computed(() => props.oktaUser.email ?? '')
    const countries = ref<ICountry[]>([])

    onMounted(async () => {
      countries.value = await countryService.getCountries()
    })

    const UpdateUserClicked = async () => {
      await updateUser({
        firstName: firstName.value,
        lastName: lastName.value,
        countryCode: countryCode.value,
        dialingCode: phoneNumber.value.dialingCode.toString(),
        phoneNumber: phoneNumber.value.number,
        email: email.value
      })
    }

    function createNameInitials(firstName?: string, lastName?: string) {
      let initials = ''
      if (firstName) {
        initials += firstName[0].toUpperCase()
      }
      if (lastName) {
        initials += lastName[0].toUpperCase()
      }
      if (!initials) {
        return '?'
      }
      return initials
    }

    function formatCountryName(countryCode?: string) {
      const country = countries.value.find(country => country.code === countryCode)
      return country?.name ?? '?'
    }

    return {
      firstName,
      lastName,
      phoneNumber,
      countryCode,
      CountryAddressDetails,
      email,
      UpdateUserClicked,
      createNameInitials,
      formatCountryName
    }
  }
}
