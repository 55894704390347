import { Configuration } from '@/config/config'
import UrlAssembler from 'url-assembler'

function GetUrlString(path: string, params: any = null) : string {
  let assembler = UrlAssembler(Configuration.API_URL_BASE).template(path)
  if (params !== null) {
    assembler = assembler.param(params)
  }

  return assembler.toString()
}

function GetServicesUrlString(path: string, params: any = null) : string {
  let assembler = UrlAssembler(Configuration.SERVICES_URL_BASE).template(path)
  if (params !== null) {
    assembler = assembler.param(params)
  }

  return assembler.toString()
}

export { GetUrlString, GetServicesUrlString }
