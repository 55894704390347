function logSuccessMessage(message: string) {
  console.log('Success: ' + message)
}

function logErrorMessage(message: string) {
  console.log('Error: ' + message)
}

function logInfoMessage(message: string) {
  console.log('Info: ' + message)
}

function logDebugMessage(message: string) {
  console.debug('Debug: ' + message)
}

export { logSuccessMessage, logErrorMessage, logInfoMessage, logDebugMessage }
