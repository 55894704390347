import { inject, provide } from 'vue'
import { ISessionService } from './SessionService.interfaces'

export const ISessionServiceSymbol = Symbol('ISessionService')

export function injectSessionService() {
  const instance = inject<ISessionService>(ISessionServiceSymbol)
  if (!instance) {
    throw new Error('There is no provided instance for ISessionService')
  }
  return instance
}

export function provideSessionService(service: ISessionService) {
  return provide(ISessionServiceSymbol, service)
}
