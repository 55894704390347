import { IStore } from '@/interfaces/common/StoreEntities'

export interface IToastItem {
  position:
    | 'top-center'
    | 'top-right'
    | 'top-left'
    | 'bottom-center'
    | 'bottom-left'
    | 'bottom-right'
  type: 'success' | 'failure'
  title: string
  subText: string
}

export interface IToastQueueViewModel {
  toasts: IToastItem[]
  head?: IToastItem
}

export type IToastItemArgs = Partial<IToastItem>

export type IToastItemArgsByType = Omit<IToastItemArgs, 'type'>

export interface IToastQueueActions {
  add(item: Partial<IToastItem>): void
  addSuccess(item: IToastItemArgsByType): void
  addError(item: IToastItemArgsByType): void
  poll(): void
}

export type IToastQueueStore = IStore<IToastQueueViewModel, IToastQueueActions>

export const IToastQueueStoreSymbol = Symbol('IToastQueueStore')
