
import { ref, onMounted } from 'vue'
import { loginAndRedirect } from '@/signin'
import { renderError } from '@/render'
import { useRoute, useRouter } from 'vue-router'
import LoginForm from '@/components/LoginForm/LoginForm.vue'
import AuthTemplateWrapper from '@/components/AuthTemplateWrapper/AuthTemplateWrapper.vue'
import LoadingMessage from '@/components/LoadingMotivationMessage/LoadingMessage.vue'
import type { LoginFormViewModel } from '@/components/LoginForm/LoginForm.interfaces'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'
import { Configuration } from '@/config/config'
import { reactivateUser } from '@/api/UserApi'
import { Skin, GetCreateUserRouteName, GetForgotPasswordRouteName, GetForgotUsernameRouteName } from '@/utils/SkinTools'

export default {
  components: {
    LoginForm,
    AuthTemplateWrapper,
    LoadingMessage
  },
  setup() {
    const sessionService = injectSessionService()
    const { showErrorToast } = useToast()
    const form = ref<InstanceType<typeof LoginForm>>()
    const router = useRouter()
    const route = useRoute()
    const sessionId = route.params.id as string
    const nonce = route.query.nonce as string
    const register = route.query.register as string
    const disabled = ref(false)
    const signingIn = ref(false)
    const hideRegister = ref(false)
    const localId = ref('')
    const createUserRouteName = ref('')
    const forgotPasswordRouteName = ref('')
    const forgotUsernameRouteName = ref('')

    function handleForgotPw() {
      router.push({
          name: forgotPasswordRouteName.value,
          params: { id: sessionId },
          query: { nonce }
        })
    }

    function handleForgotUn() {
      router.push({
          name: forgotUsernameRouteName.value,
          params: { id: sessionId },
          query: { nonce }
        })
    }

    function handleRegister() {
      const requireVerify = Configuration.REQUIRE_VERIFICATION

      if(requireVerify.toLowerCase() === 'true') {
        router.push({
          name: 'Register',
          params: { id: sessionId },
          query: { nonce }
        })
      } else {
        router.push({
          name: createUserRouteName.value,
          params: { id: sessionId },
          query: { nonce }
        })
      }
    }

    async function handleSubmit(formData: LoginFormViewModel) {
      try {
        disabled.value = true
        await loginAndRedirect(formData.email, formData.password, sessionId)
        form.value?.resetPassword()
      } catch (error) {
        await reactivateUser(localId.value, sessionId, formData.email)

        showErrorToast({
          title: 'Cannot Sign In',
          subText:
            'There was an issue signing you in. Please review your credentials and try again. If you are still experiencing issues, please check your email.'
        })
      } finally {
        disabled.value = false
      }
    }

    async function checkLocalStorage() {
      try {
        await sessionService.updateSession(sessionId, nonce)
        localId.value = sessionService.getLocalId(sessionId)
      } catch (error: any) {
        if (error.response) {
          await renderError(
            error.response.status,
            `${error.message}: ${error.response.data}`
          )
        } else {
          await renderError('400', error.message)
        }
      }
    }

    onMounted(async () => {
      document.title = 'Account Sign-in'
      hideRegister.value = (register == 'hide')

      const currentSkin = route.meta.siteSkin as Skin
      createUserRouteName.value = GetCreateUserRouteName(currentSkin)
      forgotPasswordRouteName.value = GetForgotPasswordRouteName(currentSkin)
      forgotUsernameRouteName.value = GetForgotUsernameRouteName(currentSkin)
      await checkLocalStorage()
    })

    return{
      form,
      disabled,
      signingIn,
      register,
      hideRegister,
      handleRegister,
      handleSubmit,
      handleForgotPw,
      handleForgotUn
    }
  }
}
