import postalAddressCountries from 'postal-address-field-names'
export interface Data {
  [key: string]: unknown
}

export interface TextValue<Type>{
  text: string
  value: Type
}

export interface TextValueTitle<Type>{
  text: string
  value: Type
  title: string
}

export interface AddressFields {
  country?: string
  addressLine1?: string
  addressLine2?: string
  administrativeArea?: string
  dependentLocality?: string
  locality?: string
  postalCode?: string
}

export const CountryAddressDetails: { name: string, isoCode: string, addressFields: AddressFields }[] = []
for (const country of postalAddressCountries) {
  CountryAddressDetails.push({ name: country.name, isoCode: country.iso, addressFields: country.fields })
}
