import './styles/barbri-bootstrap/barbri-bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import 'animate.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import OktaVue from '@okta/okta-vue'
import { InitOktaAuth } from './okta'
import { authInterceptor } from './interceptors/authInterceptor'
import { InitConfiguration } from './config/config'
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/styles/barbri-primevue.scss'

(async () => {
  await InitConfiguration()
  authInterceptor()
  InitOktaAuth()
  const { oktaAuth } = await import('@/okta')

  createApp(App)
    .use(store)
    .use(router)
    .use(OktaVue, {
      oktaAuth
    })
    .use(PrimeVue)
    .component('prime-dialog', Dialog)
    .mount('#app')
})()
