
import { useRoute } from 'vue-router'
import { defineComponent, ref, onMounted, computed } from 'vue'
import { getSession } from '@/api/SessionApi'
import UrlAssembler from 'url-assembler'
import { logErrorMessage } from '@/logging'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'

export default defineComponent({
  setup() {
    const sessionService = injectSessionService()
    const route = useRoute()
    const errorMessage = route.params.msg as string
    const redirectUrl = ref('')

    const errorText = computed((): string => {
      switch (errorMessage) {
        case '400': //BadRequestObjectResult
          return (
            'We were unable to complete your request based on the session information provided. ' +
            'It is possible that your current session has expired or is invalid.'
          )
        case '401': //UnauthorizedResult
          return (
            'You are unauthorized to make the request you were attempting. ' +
            'It is possible that your current session has expired or is invalid.'
          )
        case '404': //NotFoundResult
          return (
            'We were unable to locate the data necessary to make that request. ' +
            'It is possible that your current session has expired or is invalid.'
          )
        case '409': //ConflictResult
          return (
            'It seems that your current session does not match what we have stored for you. ' +
            'It is possible that your current session has expired or is invalid.'
          )
        case '500': //Internal Error
          return 'Our server encountered an unexpected condition that prevented it from fulfilling the request.'
        default:
          //Custom Errors
          return errorMessage
      }
    })

    const redirectUser = async () => {
      location.assign(redirectUrl.value)
    }

    const setRedirect = async () => {
      try {
        const currentSessionId = sessionService.forceGetSession()
        const localId = sessionService.forceGetLocalId(currentSessionId)

        const session = await getSession(currentSessionId, localId, false)
        const baseUrl = session.redirectUrl
        const queryParams = session.data
        redirectUrl.value = UrlAssembler(baseUrl)
          .query({ state: queryParams })
          .toString()
      } catch {
        logErrorMessage('Unable to retrieve session info.')
        redirectUrl.value = ''
      }
    }

    onMounted(async () => {
      await setRedirect()
    })

    return {
      errorText,
      redirectUrl,
      redirectUser,
      setRedirect
    }
  }
})
