
import { computed, ref, onMounted } from 'vue'
import router from '@/router'
import { sendOtp, validateOtp } from '@/api/UserApi'
import { useRoute } from 'vue-router'
import { renderError } from '@/render'
import { logErrorMessage } from '@/logging'
import AuthTemplateWrapper from '@/components/AuthTemplateWrapper/AuthTemplateWrapper.vue'
import CodeValidation from '@/components/CodeValidation/CodeValidation.vue'
import type { ICodeValidationChallengeEvent } from '@/components/CodeValidation/CodeValidation.interfaces'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'

export default {
  components: {
    AuthTemplateWrapper,
    CodeValidation
  },
  setup() {
    const sessionService = injectSessionService()
    const { showSuccessToast, showErrorToast } = useToast()
    const route = useRoute()
    const localId = ref('')
    const sessionId = route.params.id as string
    const nonce = route.query.nonce as string

    const userEmail = computed<string>(() => {
      try {
        return sessionService.getUserEmail()
      } catch (error) {
        renderError('400', 'Could not retrieve email from the session storage.')
        return ''
      }
    })

    async function verifyOtp(event: ICodeValidationChallengeEvent) {
      try {
        const otpResponse = await validateOtp(
          localId.value,
          sessionId,
          userEmail.value,
          event.code
        )

        event.resolve(otpResponse.isValidOtp && !otpResponse.doesUserExist)

        if (!otpResponse.isValidOtp) {
          showErrorToast({
            subText:
              'That code is not valid. Please try again or request a new one.'
          })
          return
        }

        if (otpResponse.doesUserExist) {
          showErrorToast({
            subText:
              'A user with that email already exists for a BARBRI, PowerScore or West Academic account. Please sign-in or register with a new email address.'
          })
          goToSignIn()
          return
        }
      } catch (error: any) {
        event.resolve(false)
        if (error.response) {
          await renderError(
            error.response.status,
            `${error.message}: ${error.response?.data ?? ''}`
          )
        } else {
          await renderError('400', error.message)
        }
      }
    }

    function goToCreateUser() {
      router.push({
        name: 'CreateUser',
        params: { id: sessionId },
        query: { nonce }
      })
    }

    function goToSignIn() {
      router.push({
        name: 'SignIn',
        params: { id: sessionId },
        query: { nonce }
      })
    }

    const generateOtp = async () => {
      try {
        await sendOtp(localId.value, sessionId, userEmail.value)
        showSuccessToast({
          subText: `We have sent a new code to ${userEmail.value}. Please check your email.`
        })
      } catch (error: any) {
        const errorText = error.response.data.toLowerCase()
        if(errorText.includes('email')) {
          logErrorMessage(`${error.message}: ${error.response?.data ?? ''}`)
          showErrorToast({
            subText:
              'There was an issue sending your verification code. Please ensure your email is valid and try again.'
          })
        } else {
          renderError(error.response.status, `${error.message}: ${error.response.data}`)
        }
      }
    }

    onMounted(async () => {
      localId.value = sessionService.getLocalId(sessionId)
    })

    return {
      userEmail,
      verifyOtp,
      generateOtp,
      goToCreateUser
    }
  }
}
