import { inject, provide } from 'vue'
import type { ICountryService } from '@/providers/CountryService/CountryService.interfaces'

export const ICountryServiceSymbol = Symbol('ICountryService')

export function injectCountryService() {
  const instance = inject<ICountryService>(ICountryServiceSymbol)
  if (!instance) {
    throw new Error('There is no provided instance for ICountryService')
  }
  return instance
}

export function provideCountryService(service: ICountryService) {
  return provide(ICountryServiceSymbol, service)
}
