
import { onMounted } from 'vue'
import { createSession } from '@/api/SessionApi'
import { Configuration } from '@/config/config'
import { Skin } from '@/utils/SkinTools'
export default {
  setup() {
    onMounted(async () => {
      const ssuUrl = await createSession(Configuration.PE_REDIRECT, false, Skin.pe)
      location.assign(ssuUrl)
    })
  }
}
