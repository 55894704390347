import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c96f67ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  selected: "",
  disabled: "",
  value: ""
}
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "d-flex justify-content-center" }
const _hoisted_6 = { class: "checkbox-wrapper w-100 lh-sm" }
const _hoisted_7 = {
  href: "https://www.barbri.com/terms-of-use/",
  target: "_blank",
  class: "CompleteRegistrationForm__terms-link"
}
const _hoisted_8 = {
  href: "https://www.barbri.com/privacy-policy/",
  target: "_blank",
  class: "CompleteRegistrationForm__terms-link"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "mt-3 d-flex justify-content-center" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsPhoneNumberDropdown = _resolveComponent("BsPhoneNumberDropdown")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_PasswordTooltipContent = _resolveComponent("PasswordTooltipContent")!
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")!
  const _component_RecaptchaComponent = _resolveComponent("RecaptchaComponent")!

  return (_openBlock(), _createElementBlock("form", {
    id: _ctx.id,
    class: "CompleteRegistrationForm",
    novalidate: "",
    "aria-labelledby": _ctx.titleId,
    onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("p", {
      id: _ctx.titleId,
      class: _normalizeClass(["CompleteRegistrationForm__title", { [_ctx.siteSkin + '-text']: true }])
    }, _toDisplayString(_ctx.locales.title), 11, _hoisted_2),
    _createVNode(_component_BsTextField, {
      id: _ctx.id + '__email',
      modelValue: _ctx.formFields.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.email) = $event)),
      class: "mb-3",
      type: "email",
      label: _ctx.locales.email_address,
      placeholder: _ctx.locales.email_example,
      validation: _ctx.v$.email,
      "auto-trim": "",
      disabled: _ctx.requireVerify
    }, null, 8, ["id", "modelValue", "label", "placeholder", "validation", "disabled"]),
    _createVNode(_component_BsTextField, {
      id: _ctx.id + '__first_name',
      modelValue: _ctx.formFields.firstName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formFields.firstName) = $event)),
      class: "mb-3",
      label: _ctx.locales.first_name,
      placeholder: "first name",
      validation: _ctx.v$.firstName,
      "auto-trim": "",
      maxlength: "50",
      disabled: _ctx.disabled
    }, null, 8, ["id", "modelValue", "label", "validation", "disabled"]),
    _createVNode(_component_BsTextField, {
      id: _ctx.id + '__last_name',
      modelValue: _ctx.formFields.lastName,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formFields.lastName) = $event)),
      class: "mb-3",
      label: _ctx.locales.last_name,
      placeholder: "last name",
      validation: _ctx.v$.lastName,
      "auto-trim": "",
      maxlength: "50",
      disabled: _ctx.disabled
    }, null, 8, ["id", "modelValue", "label", "validation", "disabled"]),
    _createVNode(_component_BsPhoneNumberDropdown, {
      id: _ctx.id + '__phone_number',
      modelValue: _ctx.formFields.phoneNumber,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formFields.phoneNumber) = $event)),
      class: "mb-3",
      label: _ctx.locales.phone_number,
      validation: _ctx.v$.phoneNumber,
      maxlength: "20",
      disabled: _ctx.disabled
    }, null, 8, ["id", "modelValue", "label", "validation", "disabled"]),
    _createVNode(_component_BsSelect, {
      id: _ctx.id + '__country',
      modelValue: _ctx.formFields.country,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formFields.country) = $event)),
      class: "mb-3",
      label: _ctx.locales.country,
      validation: _ctx.v$.country,
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.locales.select_a_country), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
          return (_openBlock(), _createElementBlock("option", {
            key: country.code,
            value: country.code
          }, _toDisplayString(country.name), 9, _hoisted_4))
        }), 128))
      ]),
      _: 1
    }, 8, ["id", "modelValue", "label", "validation", "disabled"]),
    _createVNode(_component_BsTextField, {
      id: _ctx.id + '__password',
      modelValue: _ctx.formFields.password,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formFields.password) = $event)),
      class: "mb-3",
      type: "password",
      label: _ctx.locales.password,
      placeholder: _ctx.locales.password,
      validation: _ctx.v$.password,
      disabled: _ctx.disabled,
      "toggle-password": ""
    }, {
      tooltip: _withCtx(() => [
        _createVNode(_component_PasswordTooltipContent, {
          validation: _ctx.v$.password
        }, null, 8, ["validation"])
      ]),
      _: 1
    }, 8, ["id", "modelValue", "label", "placeholder", "validation", "disabled"]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BsCheckbox, {
          id: _ctx.id + '__terms',
          modelValue: _ctx.formFields.marketingOptIn,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formFields.marketingOptIn) = $event)),
          disabled: _ctx.disabled,
          validation: _ctx.v$.acceptTermsAndConditions
        }, {
          label: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.locales.marketing_opt_in), 1),
            _createElementVNode("span", null, [
              _createTextVNode(_toDisplayString(_ctx.locales.i_agree) + " ", 1),
              _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.locales.terms_of_service), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.locales.and), 1),
              _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.locales.privacy_policy), 1)
            ])
          ]),
          _: 1
        }, 8, ["id", "modelValue", "disabled", "validation"])
      ])
    ]),
    (_ctx.requireRecaptcha)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_RecaptchaComponent, {
            id: _ctx.id + '__recaptcha',
            modelValue: _ctx.formFields.recaptchaToken,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formFields.recaptchaToken) = $event)),
            validation: _ctx.v$.recaptchaToken
          }, null, 8, ["id", "modelValue", "validation"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        type: "submit",
        class: "btn btn-primary",
        disabled: _ctx.disabled
      }, _toDisplayString(_ctx.locales.register), 9, _hoisted_11)
    ])
  ], 40, _hoisted_1))
}