import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegisterEmailForm = _resolveComponent("RegisterEmailForm")!
  const _component_AuthTemplateWrapper = _resolveComponent("AuthTemplateWrapper")!

  return (_openBlock(), _createBlock(_component_AuthTemplateWrapper, {
    "slide-left": "",
    "slide-up": ""
  }, {
    content: _withCtx(() => [
      _createVNode(_component_RegisterEmailForm, {
        ref: "registerForm",
        disabled: $setup.disabled,
        "login-route": $setup.loginRoute,
        onSubmit: $setup.generateOtp
      }, null, 8, ["disabled", "login-route", "onSubmit"])
    ]),
    _: 1
  }))
}