// @ts-ignore
import { OktaAuth, UserClaims } from '@okta/okta-auth-js'
import { Configuration } from '@/config/config'

interface UserClaimsExtended extends UserClaims {
  firstName?: string
  lastName?: string
  phoneNumber?: string
  dialingCode?: number
  countryCode?: string
}

// ts-ignore
let oktaAuth: OktaAuth = {} as OktaAuth

const InitOktaAuth = () => {
  oktaAuth = new OktaAuth({
    issuer: Configuration.OKTA_DOMAIN + 'oauth2/default',
    clientId: Configuration.CLIENT_ID,
    redirectUri: window.location.origin + '/completeLogin',
    scopes: ['openid', 'profile', 'email']
  })
}

export { UserClaimsExtended, InitOktaAuth, oktaAuth }
