
import {
  defineComponent,
  PropType,
  computed,
  unref,
  ref,
  onMounted,
  nextTick
} from 'vue'
import type { Validation } from '@vuelidate/core'
import { ICountry } from '@/providers/CountryService/CountryService.interfaces'
import { injectCountryService } from '@/providers/CountryService/CountryService.utils'
import { onClickOutside } from '@vueuse/core'
import CountryFlag from 'vue-country-flag-next'
import BsPhoneNumberDropdownItem from './BsPhoneNumberDropdownItem.vue'
import type { IPhoneNumber } from './BsPhoneNumberDropdown.interfaces'
import { createAutoIncrementId } from '@/utils/VueTools'
import locales from './BsPhoneNumberDropdown.locales.en.json'
import { useOutsideWheel } from '@/utils/HandleOutsideWheel'

export const BsPhoneNumberDropdown = defineComponent({
  name: 'BsPhoneNumberDropdown',
  components: { CountryFlag, BsPhoneNumberDropdownItem },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('BsPhoneNumberDropdown')
    },
    modelValue: {
      type: Object as PropType<IPhoneNumber>,
      default: () => ({ dialingCode: '', number: '', countryCode: '' })
    },
    label: String,
    disabled: Boolean,
    validation: Object as PropType<Validation>
  },
  emits: ['update:modelValue', 'country-updated'],
  setup(props, { emit }) {
    const countryService = injectCountryService()
    const container = ref<HTMLDivElement>()
    const input = ref<HTMLInputElement>()
    const dropdown = ref<HTMLUListElement>()
    const button = ref<HTMLButtonElement>()
    const countries = ref<ICountry[]>([])
    const unFrequentCountries = ref<ICountry[]>([])
    const frequentCountries = ref<ICountry[]>([])
    const opened = ref<boolean>(false)
    const selectedCountry = ref<ICountry>()
    const focusedCountry = ref<ICountry>()
    const dropdownMaxWidth = ref<number>(0)
    const dropdownMaxHeight = ref<number>(0)
    const dropdownTopOffset = ref<number>(0)
    const inputId = computed(() => props.id + '__input')
    const dropdownId = computed(() => props.id + '__dropdown')
    const invalidMessageId = computed(() => props.id + '__invalid_message')

    const focusedId = computed(() => {
      const code = focusedCountry.value?.code
      return code ? createFocusedId(code) : ''
    })

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const showRequiredAttr = computed<boolean>(() => {
      return props.validation
        ? Object.keys(props.validation).includes('required')
        : false
    })

    function createFocusedId(suffix: string) {
      return props.id + '__item__' + suffix.toLowerCase()
    }

    function handleInput(event: InputEvent) {
      const target = event.target as HTMLInputElement
      emit('update:modelValue', {
        ...props.modelValue,
        number: target.value
      })
    }

    function handleDropdownButtonClick() {
      opened.value = !opened.value
      if (!opened.value) {
        return
      }
      updateDropdownPositionAndDimension()
      focusCountry(selectedCountry.value)
    }

    async function focusCountry(country?: ICountry) {
      focusedCountry.value = country
      await nextTick()
      dropdown.value
        ?.querySelector('.BsPhoneNumberDropdownItem[focused="true"]')
        ?.scrollIntoView()
    }

    async function updateDropdownPositionAndDimension() {
      const containerRect = container.value?.getBoundingClientRect()
      const inputRect = input.value?.getBoundingClientRect()
      const documentRect = document.documentElement.getBoundingClientRect()
      if (!containerRect || !inputRect) {
        return
      }
      await nextTick()
      dropdownTopOffset.value = inputRect.bottom
      dropdownMaxWidth.value = containerRect.width
      dropdownMaxHeight.value =
        document.documentElement.scrollHeight +
        documentRect.top -
        inputRect.y -
        inputRect.height
    }

    function closeDropdownMenu() {
      opened.value = false
    }

    function moveDropdownCursorDown() {
      if (!opened.value) {
        return
      }
      if (!focusedCountry.value) {
        focusedCountry.value = countries.value[0]
        return
      }
      const index = countries.value.indexOf(focusedCountry.value)
      const nextCountry = countries.value[index + 1]
      if (nextCountry) {
        focusedCountry.value = nextCountry
        nextTick(scrollDownFocusedDropdownItem)
      }
    }

    function moveDropdownCursorUp() {
      if (!opened.value) {
        return
      }
      if (!focusedCountry.value) {
        focusedCountry.value = countries.value[0]
        return
      }
      const index = countries.value.indexOf(focusedCountry.value)
      const nextCountry = countries.value[index - 1]
      if (nextCountry) {
        focusedCountry.value = nextCountry
        nextTick(scrollUpFocusedDropdownItem)
      }
    }

    function scrollDownFocusedDropdownItem() {
      const dropdownEl = getDropdownElement()
      const focusedItemEl = getFocusedDropdownItemElement()
      const dropdownRect = dropdownEl.getBoundingClientRect()
      const itemRect = focusedItemEl.getBoundingClientRect()
      if (
        dropdownRect.y + dropdownRect.height <=
        itemRect.y + itemRect.height
      ) {
        focusedItemEl.scrollIntoView(false)
      } else if (dropdownRect.y > itemRect.y) {
        focusedItemEl.scrollIntoView()
      }
    }

    function scrollUpFocusedDropdownItem() {
      const dropdownEl = getDropdownElement()
      const focusedItemEl = getFocusedDropdownItemElement()
      const dropdownRect = dropdownEl.getBoundingClientRect()
      const itemRect = focusedItemEl.getBoundingClientRect()
      if (dropdownRect.y > itemRect.y) {
        focusedItemEl.scrollIntoView()
      } else if (
        dropdownRect.y + dropdownRect.height <=
        itemRect.y + itemRect.height
      ) {
        focusedItemEl.scrollIntoView(false)
      }
    }

    function getFocusedDropdownItemElement() {
      const dropdownEl = getDropdownElement()
      const focusedItemEl = dropdownEl.querySelector(
        '.BsPhoneNumberDropdownItem[focused="true"]'
      )
      if (!(focusedItemEl instanceof HTMLElement)) {
        throw new Error('Missing dropdown element')
      }
      return focusedItemEl
    }

    function getDropdownElement() {
      const dropdownEl = dropdown.value
      if (!dropdownEl) {
        throw new Error('Missing focused dropdown item element')
      }
      return dropdownEl
    }

    function openDropdownOrSelectCountry(event: KeyboardEvent) {
      event.preventDefault()
      event.stopPropagation()
      if (!opened.value) {
        handleDropdownButtonClick()
        return
      }
      closeDropdownMenu()
      selectedCountry.value = focusedCountry.value
      emit('update:modelValue', {
        ...props.modelValue,
        dialingCode: selectedCountry.value?.dialingCode,
        countryCode: selectedCountry.value?.code
      })
      emit('country-updated')
      input.value?.focus()
    }

    function handleDropdownItemClick(country: ICountry) {
      opened.value = false
      selectedCountry.value = country
      emit('update:modelValue', {
        ...props.modelValue,
        dialingCode: selectedCountry.value?.dialingCode,
        countryCode: selectedCountry.value?.code
      })
      emit('country-updated')
      input.value?.focus()
    }

    function focusNextItemByCharacter(event: KeyboardEvent) {
      let occurrence: ICountry | undefined
      if (event.key.match(/[a-zA-Z]/g)) {
        event.preventDefault()
        if (focusedCountry.value?.name.startsWith(event.key.toUpperCase())) {
          const currentIndex = countries.value.indexOf(focusedCountry.value)
          occurrence = countries.value.find(
            (country, index) =>
              index > currentIndex &&
              country.name.startsWith(event.key.toUpperCase())
          )
          if (!occurrence) {
            occurrence = countries.value.find((country) =>
              country.name.startsWith(event.key.toUpperCase())
            )
          }
        } else {
          occurrence = countries.value.find((country) =>
            country.name.startsWith(event.key.toUpperCase())
          )
        }
      } else if (event.key.match(/[0-9]/g)) {
        event.preventDefault()
        if (
          focusedCountry.value?.dialingCode.toString().startsWith(event.key)
        ) {
          const currentIndex = countries.value.indexOf(focusedCountry.value)
          occurrence = countries.value.find(
            (country, index) =>
              index > currentIndex &&
              country.dialingCode.toString().startsWith(event.key)
          )
          if (!occurrence) {
            occurrence = countries.value.find((country) =>
              country.dialingCode.toString().startsWith(event.key)
            )
          }
        } else {
          occurrence = countries.value.find((country) =>
            country.dialingCode.toString().startsWith(event.key)
          )
        }
      }
      if (occurrence) {
        focusCountry(occurrence)
      }
    }

    function handleDropdownButtonKeyDown(event: KeyboardEvent) {
      switch (event.key) {
        case 'Escape':
        case 'Tab':
          closeDropdownMenu()
          break
        case 'ArrowDown':
          event.preventDefault()
          moveDropdownCursorDown()
          break
        case 'ArrowUp':
          event.preventDefault()
          moveDropdownCursorUp()
          break
        case 'Enter':
        case 'Space':
        case ' ':
          openDropdownOrSelectCountry(event)
          break
        default:
          focusNextItemByCharacter(event)
      }
    }

    function handleChange() {
      emit('update:modelValue', {
        number: props.modelValue.number.trim(),
        dialingCode: selectedCountry.value?.dialingCode,
        countryCode: selectedCountry.value?.code
      })
    }

    async function retrieveCountries() {
      const retrievedCountries = (await countryService?.getCountries()) ?? []
      const frequentCountries: ICountry[] = []
      const unFrequentCountries: ICountry[] = []
      for (const country of retrievedCountries) {
        if (country.frequent) {
          frequentCountries.push(country)
        } else {
          unFrequentCountries.push(country)
        }
      }
      frequentCountries.sort((a, b) => {
        const aFrequent = a.frequent ?? Number.MIN_SAFE_INTEGER
        const bFrequent = b.frequent ?? Number.MIN_SAFE_INTEGER
        return aFrequent - bFrequent
      })

      return { frequentCountries, unFrequentCountries }
    }

    onMounted(async () => {
      const retrievedCountries = await retrieveCountries()
      unFrequentCountries.value = retrievedCountries.unFrequentCountries
      frequentCountries.value = retrievedCountries.frequentCountries
      countries.value = [
        ...frequentCountries.value,
        ...unFrequentCountries.value
      ]

      const startingCountry = countries.value.find(x => x.dialingCode.toString() === props.modelValue.dialingCode)
      selectedCountry.value = startingCountry ?? frequentCountries.value[0]
      emit('update:modelValue', {
        ...props.modelValue,
        dialingCode: selectedCountry.value?.dialingCode,
        countryCode: selectedCountry.value?.code
      })
    })

    onClickOutside(dropdown, (event) => {
      if (
        event.target instanceof HTMLElement &&
        button.value?.contains(event.target)
      ) {
        return
      }
      closeDropdownMenu()
    })

    useOutsideWheel(opened, dropdown, closeDropdownMenu)

    return {
      inputId,
      dropdownId,
      invalidMessageId,
      locales,
      dirty,
      invalid,
      invalidMessage,
      showRequiredAttr,
      container,
      input,
      dropdown,
      button,
      dropdownMaxWidth,
      dropdownMaxHeight,
      dropdownTopOffset,
      unFrequentCountries,
      frequentCountries,
      opened,
      selectedCountry,
      focusedCountry,
      focusedId,
      handleInput,
      handleChange,
      handleDropdownButtonClick,
      handleDropdownButtonKeyDown,
      handleDropdownItemClick,
      createFocusedId
    }
  }
})

export default BsPhoneNumberDropdown
