
import MyAccountProfile from '@/components/MyAccountProfile.vue'
import EditProfileDialog from '@/components/EditProfileDialog/EditProfileDialog.vue'
import { EditProfileViewModel } from '@/components/EditProfileDialog/EditProfileDialog.interfaces'
import { updateUser } from '@/api/UserApi'
import { oktaAuth, UserClaimsExtended } from '@/okta'
import { onMounted, ref } from 'vue'
import {useToast} from '@/components/ToastQueue/ToastQueue.utils'
import {renderError} from '@/render'

export default {
  components: {
    MyAccountProfile,
    EditProfileDialog
  },
  setup() {
    const { showSuccessToast, showErrorToast } = useToast()

    const isLoading = ref(false)
    const isReady = ref(false)
    const oktaUser = ref({} as UserClaimsExtended)
    const editProfileDialog = ref<InstanceType<typeof EditProfileDialog>>()

    const getOktaUser = async () => {
      try {
        isLoading.value = true
        oktaUser.value = await oktaAuth.getUser()
        isReady.value = true
      } catch (error: any) {
        await renderError('Cannot retrieve user session', error.message)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(getOktaUser)

    function handleEditButtonClick() {
      editProfileDialog.value?.open({
        firstName: oktaUser.value.firstName ?? oktaUser.value.given_name ?? '',
        lastName: oktaUser.value.lastName ??  oktaUser.value.family_name ?? '',
        email: oktaUser.value.email ?? '',
        phoneNumber: {
          number: oktaUser.value.phoneNumber ?? '',
          dialingCode: oktaUser.value.dialingCode?.toString() ?? '1'
        },
        country: oktaUser.value.countryCode ?? ''
      })
    }

    async function handleEditUserSubmit(model: EditProfileViewModel) {
      try {
        isLoading.value = true
        await updateUser({
          email: model.email,
          firstName: model.firstName,
          lastName: model.lastName,
          countryCode: model.country,
          phoneNumber: model.phoneNumber.number,
          dialingCode: model.phoneNumber.dialingCode.toString()
        })
        showSuccessToast({
          title: 'Success',
          subText: 'Your profile has been successfully updated'
        })
        editProfileDialog.value?.close()
        await getOktaUser()
      } catch (error: any) {
        showErrorToast({
          title: 'Error',
          subText: 'Cannot update profile'
        })
      } finally {
        isLoading.value = false
      }
    }

    return {
      oktaUser,
      editProfileDialog,
      isLoading,
      isReady,
      handleEditButtonClick,
      handleEditUserSubmit
    }
  }
}
