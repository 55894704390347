
import { defineComponent, ref } from 'vue'
import locales from './PasswordTooltipContent.locales.en.json'

export const PasswordTooltipContent = defineComponent({
  name: 'PasswordTooltipContent',
  props: {
    validation: Object
  },
  setup() {
    const passwordRules = ref([
      { name: locales.rule_eight_char, validator: 'minLengthEight' },
      { name: locales.rule_upper_case, validator: 'hasUpperCase' },
      { name: locales.rule_lower_case, validator: 'hasLowerCase' },
      { name: locales.rule_number, validator: 'hasNumber' },
      { name: locales.rule_first_name, validator: 'noFirstName' },
      { name: locales.rule_last_name, validator: 'noLastName' },
      { name: locales.rule_username, validator: 'noUsername' },
      { name: locales.rule_special_char, validator: 'noSpecialChar' }
    ])

    return {
      locales,
      passwordRules
    }
  }
})

export default PasswordTooltipContent
