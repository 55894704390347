
import { defineComponent, ref, watch, computed, nextTick } from 'vue'
import { injectToastQueueStore } from './ToastQueue.utils'
import Toast from '../Toast/Toast.vue'

const POLL_DELAY_MS = 250

export const ToastQueue = defineComponent({
  name: 'ToastQueue',
  components: { Toast },
  setup() {
    const store = injectToastQueueStore()
    const isOpen = ref(false)
    const head = computed(() => store?.state.head)

    watch(isOpen, (isOpen) => {
      if (!isOpen) {
        setTimeout(() => {
          store?.actions.poll()
        }, POLL_DELAY_MS)
      }
    })

    watch(head, async (head) => {
      if (head) {
        await nextTick()
        isOpen.value = true
      }
    })

    return {
      head,
      isOpen
    }
  }
})

export default ToastQueue
