import { ISessionService } from './SessionService.interfaces'
import locales from './SessionService.locales.en.json'
import { provideSessionService } from './SessionService.utils'
import { setSessionLocalId } from '@/api/SessionApi'
import { renderError } from '@/render'

export * from './SessionService.interfaces'
export * from './SessionService.utils'

export function useSessionService(): ISessionService {
  function getLocalId(sessionId: string) {
    const localId = localStorage.getItem('local_' + sessionId)
    if (localId && !!sessionId) {
      return localId
    }
    throw new Error(locales.cannot_retrieve_session_id)
  }

  function forceGetLocalId(sessionId: string) {
    return localStorage.getItem('local_' + sessionId)!
  }

  function getUserEmail() {
    const email = sessionStorage.getItem('email')
    if (email) {
      return email
    }
    throw new Error(locales.cannot_retrieve_email_message)
  }

  function setUserEmail(email: string) {
    sessionStorage.setItem('email', email)
  }

  function forceGetSession() {
    return localStorage.getItem('current_session')!
  }

  async function updateSession(sessionId?: string, nonce?: string) {
    if (localStorage.getItem('local_' + sessionId)) {
      return
    }

    localStorage.removeItem('current_session')
    await removeLocalIds()
    await setSession(sessionId, nonce)
  }

  async function removeLocalIds() {
    const localStorageKeys = []
    for (let i = 0; i < localStorage.length; i++) {
      if (!localStorage.key(i)) {
        continue
      }

      localStorageKeys.push(localStorage.key(i)!.toString())
    }

    localStorageKeys.forEach((key) => {
      if (key.startsWith('local_')) {
        localStorage.removeItem(key)
      }
    })
  }

  async function setSession(sessionId?: string, nonce?: string) {
    if (!sessionId || !nonce) {
      throw new Error(locales.cannot_retrieve_route_values_message)
    }

    if (!/[a-f0-9]+/.test(sessionId)) {
      throw new Error('SessionId is invalid.')
    }

    const localId = self?.crypto?.randomUUID?.() ?? Math.random().toString()

    localStorage.setItem('current_session', sessionId)
    localStorage.setItem('local_' + sessionId, localId)

    try{
      await setSessionLocalId(sessionId, nonce, localId)
    } catch (error: any){
      renderError(error.response.status, `${error.message}: ${error.response.data}`)
    }
  }

  return {
    getLocalId,
    forceGetLocalId,
    getUserEmail,
    setUserEmail,
    forceGetSession,
    updateSession
  }
}

export function autoProvideSessionService() {
  const instance = useSessionService()
  provideSessionService(instance)
  return instance
}
