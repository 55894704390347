import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForgotUsernameForm = _resolveComponent("ForgotUsernameForm")!
  const _component_AuthTemplateWrapper = _resolveComponent("AuthTemplateWrapper")!

  return (_openBlock(), _createBlock(_component_AuthTemplateWrapper, {
    "site-skin": _ctx.$route.meta.siteSkin,
    "slide-left": "",
    "slide-up": ""
  }, {
    content: _withCtx(() => [
      _createVNode(_component_ForgotUsernameForm, {
        ref: "forgotUsernameForm",
        disabled: $setup.disabled,
        onSubmit: $setup.sendForgotUsernameEmail
      }, null, 8, ["disabled", "onSubmit"])
    ]),
    _: 1
  }, 8, ["site-skin"]))
}