
import {defineComponent, ref, computed, nextTick} from 'vue'
import ValidCodeIcon from '../../../public/icons/CodeValidationSuccess.svg'
import InvalidCodeIcon from '../../../public/icons/CodeValidationFailure.svg'
import { ICodeValidationChallengeEvent } from './CodeValidation.interfaces'
import locales from './CodeValidation.locales.en.json'

const CODE_LENGTH = 6
const numberValidator = /^[0-9]*$/

function createInitialInputValues() {
  return [...Array(CODE_LENGTH)].map(() => '')
}

function _sanitizeEventKey(key: string): string | undefined {
  return key === 'Unidentified' ? undefined : key
}

export const CodeVerification = defineComponent({
  name: 'CodeVerification',
  components: {
    ValidCodeIcon,
    InvalidCodeIcon
  },
  props: {
    email: {
      type: String,
      default: 'test@gmail.com'
    },
    resetInterval: {
      type: Number,
      default: 400 // in ms
    }
  },
  emits: ['validation-challenge', 'validated', 'resend'],
  setup(props, { emit }) {
    const valueParts = ref<string[]>(createInitialInputValues())
    const codeInputWrapper = ref<HTMLDivElement>()
    const isValidationComplete = ref<boolean>(false)
    const isValidatingCode = ref<boolean>(false)
    const isCodeValid = ref<boolean>(false)
    const code = computed<string>(() => valueParts.value.join(''))

    function focusInput(index: number) {
      if (codeInputWrapper.value) {
        const input = codeInputWrapper.value?.children?.[index]
        if (input instanceof HTMLInputElement) {
          input.focus()
        }
      }
    }

    function handleKeyDown(event: KeyboardEvent, index: number) {
      const key = _sanitizeEventKey(event.key)

      if (!key) {
        return
      } else if (key === 'Backspace') {
        if (valueParts.value[index]) {
          return (valueParts.value[index] = '')
        }
        focusInput(index - 1)
      } else if (!event.shiftKey && (key === 'ArrowRight' || key === 'Right')) {
        focusInput(index + 1)
      } else if (!event.shiftKey && (key === 'ArrowLeft' || key === 'Left')) {
        focusInput(index - 1)
      }

      let allowedKeys = [9, 38, 40]

      if (numberValidator.test(key) || (event.ctrlKey && event.key === 'v')) {
        if (key.length === 1 && valueParts.value[index]) {
          valueParts.value[index] = key
          focusInput(index + 1)
          emitInput()
        }
      } else {
        if (!allowedKeys.includes(event.keyCode)) {
          event.preventDefault()
        }
      }
    }

    function handleInput(index: number) {
      const value = valueParts.value[index]

      if (value) {
        if (value.length > 1) {
          valueParts.value[index] = value[value.length - 1]
        }
        focusInput(index + 1)
      }

      emitInput()
    }

    function onPaste(event: ClipboardEvent) {
      const clipboardData = event.clipboardData || (window as any).clipboardData
      if (!clipboardData) {
        return
      }
      // IE fix
      event.preventDefault()
      let code =
        clipboardData.getData('Text') || clipboardData.getData('text/plain')
      fillCode(code)
    }

    function fillCode(code: string) {
      code = code.trim()
      code = code.slice(0, CODE_LENGTH)
      const parts = code.split('')
      parts.length = CODE_LENGTH
      valueParts.value = [...parts]

      const last = code.length - 1

      valueParts.value[last] =
        valueParts.value[last] && valueParts.value[last].slice(0, 1)
      emitInput()
      focusInput(last)
    }

    function emitInput() {
      if (valueParts.value.every((value: string) => value)) {
        isValidationComplete.value = true
        validateCode()
      }
    }

    function delay(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }

    function dispatchCodeValidationChallengeEvent() {
      return new Promise<boolean>((resolve) => {
        const event: ICodeValidationChallengeEvent = {
          code: code.value,
          resolve
        }
        emit('validation-challenge', event)
      })
    }

    async function validateCode() {
      isValidatingCode.value = true
      isCodeValid.value = await dispatchCodeValidationChallengeEvent()
      await delay(props.resetInterval)
      if (isCodeValid.value) {
        emit('validated')
      }
      valueParts.value = createInitialInputValues()
      isValidationComplete.value = false
      isValidatingCode.value = false
      await nextTick()
      focusInput(0)
    }

    function handleResendButtonClick() {
      emit('resend', props.email)
    }

    return {
      locales,
      valueParts,
      codeInputWrapper,
      handleKeyDown,
      handleInput,
      onPaste,
      handleResendButtonClick,
      isValidationComplete,
      isValidatingCode,
      isCodeValid
    }
  }
})

export default CodeVerification
