
import {defineComponent, ref, computed, onMounted, reactive} from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsPhoneNumberDropdown from '@/components/BsPhoneNumberDropdown/BsPhoneNumberDropdown.vue'
import locales from './EditProfileDialog.locales.en.json'
import { createAutoIncrementId } from '@/utils/VueTools'
import { injectCountryService } from '@/providers/CountryService/CountryService.utils'
import { ICountry } from '@/providers/CountryService/CountryService.interfaces'
import { EditProfileViewModel, EditProfileValidationArgs } from './EditProfileDialog.interfaces'
import useVuelidate from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneNumber = helpers.withMessage(
  locales.invalid_phone_number,
  (phoneNumber: {number: string, dialingCode: string, countryCode: string }) =>
    !!phoneNumber.dialingCode
    && (phoneUtil.isValidNumberForRegion(phoneUtil.parse(phoneNumber.number, phoneNumber.countryCode), phoneNumber.countryCode) ?? false)
)

const noPlusSign = helpers.withMessage(
  locales.invalid_email_plus,
  (value: string) => !(value.includes('+'))
)

export default defineComponent({
  components: { BsTextField, BsSelect, BsPhoneNumberDropdown },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('EditProfileDialog')
    },
    disabled: Boolean
  },
  emits: ['submit-form'],
  setup(props, { emit }) {
    const countryService = injectCountryService()

    const opened = ref(false)
    const countries = ref<ICountry[]>([])
    const titleId = computed<string>(() => {
      return props.id + '__title'
    })
    const formId = computed<string>(() => {
      return props.id + '__form'
    })
    const formFields = reactive<EditProfileViewModel>({
      email: '',
      firstName: '',
      lastName: '',
      country: '',
      phoneNumber: {
        number: '',
        dialingCode: ''
      }
    })
    const rules: EditProfileValidationArgs = {
      email: { required, email, noPlusSign },
      firstName: { required },
      lastName: { required },
      country: { required },
      phoneNumber: { required, isPhoneNumber }
    }

    const v$ = useVuelidate(rules, formFields)

    onMounted(async () => {
      countries.value = (await countryService?.getCountries()) ?? []
    })

    function open(model?: EditProfileViewModel) {
      opened.value = true
      if (model) {
        formFields.email = model.email
        formFields.firstName = model.firstName
        formFields.lastName = model.lastName
        formFields.phoneNumber = model.phoneNumber
        formFields.country = model.country
      }
    }

    function close() {
      opened.value = false
    }

    function handleSubmit() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('submit-form', formFields)
    }

    function handleCancel() {
      opened.value = false
    }

    return {
      locales,
      opened,
      titleId,
      formId,
      countries,
      formFields,
      v$,
      open,
      close,
      handleSubmit,
      handleCancel
    }
  }
})
