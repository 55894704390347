import type { ICountryService, ICountry } from './CountryService.interfaces'
import { provideCountryService } from '@/providers/CountryService/CountryService.utils'

export * from '@/providers/CountryService/CountryService.interfaces'
export * from '@/providers/CountryService/CountryService.utils'

export function useCountryService(): ICountryService {
  return {
    getCountries
  }

  async function getCountries(): Promise<ICountry[]> {
    const data = await import('country-telephone-data')
    const frequentCodes: Record<string, number> = { us: 1, gb: 2 }
    return data.default.allCountries.map((country) => {
      return {
        code: country.iso2.toUpperCase(),
        dialingCode: parseInt(country.dialCode, 10),
        name: country.name,
        frequent: frequentCodes[country.iso2] || 0
      }
    })
  }
}

export function autoProvideCountryService() {
  const instance = useCountryService()
  provideCountryService(instance)
  return instance
}
