
import { defineComponent, ref, getCurrentInstance, onMounted } from 'vue'
import { getSession } from '@/api/SessionApi'
import UrlAssembler from 'url-assembler'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'
import type { OktaAuth } from '@okta/okta-auth-js'
import LoadingMessage from '@/components/LoadingMotivationMessage/LoadingMessage.vue'
import { renderError } from '@/render'
import { UserClaimsExtended } from '@/okta'

function useOktaAuth() {
  try {
    return getCurrentInstance()!.appContext.app.config.globalProperties
      .$auth as OktaAuth
  } catch (error) {
    console.error('Please ensure OktaAuth is defined on your vue app')
    throw error
  }
}

export default defineComponent({
  name: 'LoginCallback',
  components: { LoadingMessage },
  setup() {
    const sessionService = injectSessionService()
    const oktaAuth = useOktaAuth()
    const authError = ref<string>('')

    function getStateFromUrl() {
      const url = new URL(window.location.href)
      if (!url.searchParams.has('state')) {
        throw new Error('State could not be defined.')
      }
      return JSON.parse(url.searchParams.get('state')!) as {
        sessionId: string
      }
    }

    function getSessionIdFromUrl() {
      const state = getStateFromUrl()
      if (state.sessionId !== sessionService.forceGetSession()) {
        throw new Error('SessionId is invalid.')
      }
      return state.sessionId
    }

    const setSalesForceCookie = async (tokenKey: string) => {
      const tokens = await oktaAuth.tokenManager.getTokens()
      const accessToken = tokens.accessToken

      if(!accessToken) {
        return
      }

      const now = new Date()
      const time = now.getTime()
      const expireTime = time + 5*60*1000 //5 minutes as milliseconds
      now.setTime(expireTime)

      const url = new URL(window.origin)
      const hostName = url.hostname.substring(url.hostname.indexOf('.') + 1)
      document.cookie = `${tokenKey}=${accessToken?.accessToken}; domain=${hostName};expires=${now.toUTCString()};`
    }

    onMounted(async () => {
      try {
        const sessionId = getSessionIdFromUrl()
        const localId = sessionService.getLocalId(sessionId)

        if (oktaAuth.isLoginRedirect()) {
          await oktaAuth.handleLoginRedirect()
          oktaAuth.start()
        } else if (!(await oktaAuth.isAuthenticated())) {
          oktaAuth.signInWithRedirect()
        }

        const userClaims = await oktaAuth.getUser() as UserClaimsExtended
        if (userClaims) {
          (window as any).FS.identify(userClaims.sub, {
            displayName: userClaims.firstName + ' ' + userClaims.lastName,
            email: userClaims.email
          })
        }

        let session
        try{
          session = await getSession(sessionId, localId)
        } catch (error: any) {
          renderError(error.response.status, `${error.message}: ${error.response.data}`)
          return
        }
        
        const baseUrl = session.redirectUrl
        const queryParams = session.data

        let query
        if(session.isSalesforce) {
          query = { retrievalId: session.retrievalId }
          await setSalesForceCookie(session.tokenKey)
        }
        else {
          query = { state: queryParams}
        }

        const redirectUrl = UrlAssembler(baseUrl).query(query).toString()
        location.assign(redirectUrl)
      } catch (e: any) {
        authError.value = e.toString()
        const { onAuthResume, onAuthRequired } = oktaAuth.options as any
        const callbackFn = onAuthResume || onAuthRequired
        if (callbackFn) {
          callbackFn(oktaAuth)
          return
        }
      }
    })
    return { authError }
  }
})
