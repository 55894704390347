import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5650220"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PasswordTooltipContent" }
const _hoisted_2 = { class: "PasswordTooltipContent__title" }
const _hoisted_3 = { class: "PasswordTooltipContent__list" }
const _hoisted_4 = ["invalid"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.locales.title) + ": ", 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.passwordRules, (rule, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "PasswordTooltipContent__list__item",
          invalid: _ctx.validation?.[rule.validator]?.$invalid ?? false
        }, _toDisplayString(rule.name), 9, _hoisted_4))
      }), 128))
    ])
  ]))
}