enum Skin {
  barbri = 'barbri',
  powerscore = 'powerscore',
  pe = 'pe',
  sqe = 'sqe'
}

function GetCreateUserRouteName(skin: Skin) {
  switch(skin)
  {
    case Skin.powerscore:
      return 'PowerScoreCreateUser'
    case Skin.pe:
      return 'PeCreateUser'
    case Skin.sqe:
      return 'SqeCreateUser'
    case Skin.barbri:
    default:
      return 'CreateUser'
  }
}

function GetSignInRouteName(skin: Skin) {
  switch(skin)
  {
    case Skin.powerscore:
      return 'PowerScoreSignIn'
    case Skin.pe:
      return 'PeSignIn'
    case Skin.sqe:
      return 'SqeSignIn'
    case Skin.barbri:
    default:
      return 'SignIn'
  }
}

function GetForgotPasswordRouteName(skin: Skin) {
  switch (skin) {
    case Skin.powerscore:
      return 'PowerScoreForgot-Password'
    case Skin.pe:
      return 'PeForgot-Password'
    case Skin.sqe:
      return 'SqeForgot-Password'
    case Skin.barbri:
    default:
      return 'Forgot-Password'
  }
}

function GetForgotUsernameRouteName(skin: Skin) {
  switch (skin) {
    case Skin.powerscore:
      return 'PowerScoreForgot-Username'
    case Skin.pe:
      return 'PeForgot-Username'
    case Skin.sqe:
      return 'SqeForgot-Username'
    case Skin.barbri:
    default:
      return 'Forgot-Username'
  }
}

export { Skin, GetCreateUserRouteName, GetSignInRouteName, GetForgotPasswordRouteName, GetForgotUsernameRouteName}
