
import { defineComponent, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import locales from './ForgotPasswordForm.locales.en.json'
import { Skin } from '@/utils/SkinTools'

export default defineComponent({
  name: 'ForgotPasswordForm',
  components: {
    BsTextField
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    siteSkin: {
      type: String,
      default: 'barbri'
    }
  },
  emits: ['submit', 'login'],
  setup(props, { emit }) {
    const formFields = reactive({
      login: ''
    })

    const rules = {
      login: { required }
    }

    const v$ = useVuelidate(rules, formFields)

    function verifyLogin() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('submit', formFields.login)
    }

    return {
      v$,
      formFields,
      verifyLogin,
      locales,
      Skin
    }
  }
})
