import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ffb1291"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-describedby"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["d-flex justify-content-center mt-4", {
      'is-invalid': $setup.dirty && $setup.invalid
    }]),
      "aria-describedby": $setup.invalidMessage && $setup.invalidMessageId
    }, [
      _createVNode(_component_vue_recaptcha, {
        ref: "vueRecaptcha",
        sitekey: $setup.recaptchaSiteKey,
        onVerify: $setup.recaptchaVerified,
        onExpire: $setup.recaptchaExpired
      }, null, 8, ["sitekey", "onVerify", "onExpire"])
    ], 10, _hoisted_1),
    ($setup.dirty)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: $setup.invalidMessage && $setup.invalidMessageId,
          class: "invalid-feedback text-center"
        }, _toDisplayString($setup.invalidMessage), 9, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}