import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { navigationGuard } from '@okta/okta-vue'
import UserSignIn from '@/views/UserSignIn.vue'
import NewUserCreation from '@/views/NewUserCreation.vue'
import NewUserRegistration from '@/views/NewUserRegistration.vue'
import MyAccount from '@/views/MyAccount.vue'
import ValidateOtp from '@/views/ValidateOtp.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ForgotUsername from '@/views/ForgotUsername.vue'
import PowerScoreRedirect from '@/views/PowerScoreRedirect.vue'
import PERedirect from '@/views/ProfessionalEducationRedirect.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import LoginCallback from '@/components/LoginCallback.vue'
import SQERedirect from '@/views/SolicitorsQualifyingExamRedirect.vue'
import { Skin } from '@/utils/SkinTools'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/SignIn/:id/',
    name: 'SignIn',
    component: UserSignIn,
    meta: {
      siteSkin: Skin.barbri
    }
  },
  {
    path: '/PowerScore/SignIn/:id/',
    name: 'PowerScoreSignIn',
    component: UserSignIn,
    meta: {
      siteSkin: Skin.powerscore
    }
  },
  {
    path: '/PE/SignIn/:id/',
    name: 'PeSignIn',
    component: UserSignIn,
    meta: {
      siteSkin: Skin.pe
    }
  },
  {
    path: '/mylearning/SignIn/:id/',
    name: 'SqeSignIn',
    component: UserSignIn,
    meta: {
      siteSkin: Skin.sqe
    }
  },
  {
    path: '/CreateUser/:id/',
    name: 'CreateUser',
    component: NewUserCreation,
    meta: {
      siteSkin: Skin.barbri
    }
  },
  {
    path: '/PowerScore/CreateUser/:id/',
    name: 'PowerScoreCreateUser',
    component: NewUserCreation,
    meta: {
      siteSkin: Skin.powerscore
    }
  },
  {
    path: '/PE/CreateUser/:id/',
    name: 'PeCreateUser',
    component: NewUserCreation,
    meta: {
      siteSkin: Skin.pe
    }
  },
  {
    path: '/mylearning/CreateUser/:id/',
    name: 'SqeCreateUser',
    component: NewUserCreation,
    meta: {
      siteSkin: Skin.sqe
    }
  },
  {
    path: '/Register/:id/',
    name: 'Register',
    component: NewUserRegistration
  },
  {
    path: '/Verify/:id/',
    name: 'Verify',
    component: ValidateOtp
  },
  {
    path: '/Error/:msg/',
    name: 'Error',
    component: ErrorDisplay
  },
  {
    path: '/Forgot-Password/:id/',
    name: 'Forgot-Password',
    component: ForgotPassword,
    meta: {
      siteSkin: Skin.barbri
    }
  },
  {
    path: '/PowerScore/Forgot-Password/:id/',
    name: 'PowerScoreForgot-Password',
    component: ForgotPassword,
    meta: {
      siteSkin: Skin.powerscore
    }
  },
  {
    path: '/PE/Forgot-Password/:id/',
    name: 'PeForgot-Password',
    component: ForgotPassword,
    meta: {
      siteSkin: Skin.pe
    }
  },
  {
    path: '/mylearning/Forgot-Password/:id/',
    name: 'SqeForgot-Password',
    component: ForgotPassword,
    meta: {
      siteSkin: Skin.sqe
    }
  },
  {
    path: '/Forgot-Username/:id/',
    name: 'Forgot-Username',
    component: ForgotUsername,
    meta: {
      siteSkin: Skin.barbri
    }
  },
  {
    path: '/PowerScore/Forgot-Username/:id/',
    name: 'PowerScoreForgot-Username',
    component: ForgotUsername,
    meta: {
      siteSkin: Skin.powerscore
    }
  },
  {
    path: '/PE/Forgot-Username/:id/',
    name: 'PeForgot-Username',
    component: ForgotUsername,
    meta: {
      siteSkin: Skin.pe
    }
  },
  {
    path: '/mylearning/Forgot-Username/:id/',
    name: 'SqeForgot-Username',
    component: ForgotUsername,
    meta: {
      siteSkin: Skin.sqe
    }
  },
  {
    path: '/completeLogin',
    component: LoginCallback
  },
  {
    path: '/MyAccount',
    component: MyAccount
  },
  {
    path: '/PowerScore',
    name: 'PowerScore-Redirect',
    component: PowerScoreRedirect
  },
  {
    path: '/PE',
    name: 'PE-Redirect',
    component: PERedirect
  },
  {
    path: '/mylearning',
    name: 'SQE-Redirect',
    component: SQERedirect
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(navigationGuard)

export default router
