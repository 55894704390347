import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingMessage = _resolveComponent("LoadingMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.authError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.authError), 1))
      : _createCommentVNode("", true),
    (!_ctx.authError)
      ? (_openBlock(), _createBlock(_component_LoadingMessage, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}