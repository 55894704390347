
import { ref, onMounted } from 'vue'
import ForgotUsernameForm from '@/components/ForgotUsernameForm/ForgotUsernameForm.vue'
import AuthTemplateWrapper from '@/components/AuthTemplateWrapper/AuthTemplateWrapper.vue'
import { useRoute } from 'vue-router'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { renderError } from '@/render'
import { forgotUsername } from '@/api/UserApi'

export default {
   components: {
    AuthTemplateWrapper,
    ForgotUsernameForm
  },
  setup() {
    const sessionService = injectSessionService()
    const route = useRoute()
    const sessionId = route.params.id as string
    const localId = ref('')
    const disabled = ref(false)
    const { showSuccessToast } = useToast()

    async function sendForgotUsernameEmail(email: string) {
      disabled.value = true

      try {
        await forgotUsername(localId.value, sessionId, email)

        showSuccessToast({
          subText:
            'If an account exists with this email address, you will receive an email shortly with your username.'
        })
      } catch (error: any) {
        renderError(error.response.status, `${error.message}: ${error.response.data}`)
      } finally {
        disabled.value = false
      }
    }

    onMounted(() => {
      localId.value = sessionService.getLocalId(sessionId)
    })

    return{
      disabled,
      sendForgotUsernameEmail
    }
  }
}
