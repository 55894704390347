
import { defineComponent, PropType } from 'vue'
import CountryFlag from 'vue-country-flag-next'
import { ICountry } from '@/providers/CountryService/CountryService'

export const BsPhoneNumberDropdownItem = defineComponent({
  name: 'BsPhoneNumberDropdownItem',
  components: { CountryFlag },
  props: {
    country: Object as PropType<ICountry>,
    focused: Boolean,
    selected: Boolean
  }
})

export default BsPhoneNumberDropdownItem
