
import { defineComponent, reactive, ref, onMounted, computed, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { helpers, required, email } from '@vuelidate/validators'
import BsTextField from '../BsTextField/BsTextField.vue'
import BsSelect from '../BsSelect/BsSelect.vue'
import BsCheckbox from '../BsCheckbox/BsCheckbox.vue'
import BsPhoneNumberDropdown from '../BsPhoneNumberDropdown/BsPhoneNumberDropdown.vue'
import PasswordTooltipContent from '../PasswordTooltipContent/PasswordTooltipContent.vue'
import { ICountry } from '@/providers/CountryService/CountryService.interfaces'
import { injectCountryService } from '@/providers/CountryService/CountryService.utils'
import type {
  CompleteRegistrationViewModel,
  CompleteRegistrationValidationArgs
} from './CompleteRegistrationForm.interfaces'
import locales from './CompleteRegistrationForm.locales.en.json'
import { createAutoIncrementId } from '@/utils/VueTools'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { Skin } from '@/utils/SkinTools'
import RecaptchaComponent from '../Recaptcha/RecaptchaComponent.vue'

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneNumber = helpers.withMessage(
  locales.invalid_phone_number,
  (phoneNumber: {number: string, dialingCode: string, countryCode: string }) =>
    !!phoneNumber.dialingCode
    && (phoneUtil.isValidNumberForRegion(phoneUtil.parse(phoneNumber.number, phoneNumber.countryCode), phoneNumber.countryCode) ?? false)
)

const minLengthEight = helpers.withMessage(
  locales.invalid_pass_length,
  (value: string) => value.length >= 8
)

const hasUpperCase = helpers.withMessage(
  locales.invalid_pass_upper_case,
  (value: string) => value !== value.toLowerCase()
)

const hasLowerCase = helpers.withMessage(
  locales.invalid_pass_lower_case,
  (value: string) => value !== value.toUpperCase()
)

const hasNumber = helpers.withMessage(
  locales.invalid_pass_number,
  (value: string) => Boolean(value.match(/[0-9]/))
)

const hasRecaptcha = (required: Boolean) => helpers.withMessage(
  locales.invalid_recaptcha,
  (value: string) =>  !(required && value == '')
)

const noFirstName = (vm: CompleteRegistrationViewModel) => helpers.withMessage(
  locales.invalid_pass_first_name,
  (value: string) => vm.firstName ? !value.includes(vm.firstName) : true
)

const noLastName = (vm: CompleteRegistrationViewModel) => helpers.withMessage(
  locales.invalid_pass_last_name,
  (value: string) => vm.lastName ? !value.includes(vm.lastName) : true
)

const noUsername = (vm: CompleteRegistrationViewModel) => helpers.withMessage(
  locales.invalid_pass_username,
  (value: string) => {
    const emailSplit = vm.email.split('@')
    return vm.email ? !(value.includes(vm.email) || value.includes(emailSplit[0]) || value.includes(emailSplit[1])) : true
})

const noSpecialChar = helpers.withMessage(
  locales.invalid_special_chars,
  (value: string) => !(value.match(/[\s\t/\\'"`]/))
)

const noPlusSign = helpers.withMessage(
  locales.invalid_email_plus,
  (value: string) => !(value.includes('+'))
)

const CompleteRegistrationForm = defineComponent({
  name: 'CompleteRegistrationForm',
  components: {
    BsTextField,
    BsSelect,
    BsCheckbox,
    BsPhoneNumberDropdown,
    PasswordTooltipContent,
    RecaptchaComponent
  },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('CompleteRegistrationForm')
    },
    learnMoreUrl: {
      type: String,
      default: '#'
    },
    disabled: Boolean,
    requireVerify: Boolean,
    requireRecaptcha: Boolean,
    siteSkin: {
      type: String,
      default: 'barbri'
    }
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const countryService = injectCountryService()
    const titleId = computed(() => props.id + '__title')
    const formFields = reactive<CompleteRegistrationViewModel>({
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: {
        number: '',
        dialingCode: '',
        countryCode: ''
      },
      country: '',
      password: '',
      marketingOptIn: false,
      recaptchaToken: ''
    })

    const rules: CompleteRegistrationValidationArgs = {
      email: { required, email, noPlusSign },
      firstName: { required },
      lastName: { required },
      country: { required },
      phoneNumber: { required, isPhoneNumber },
      password: {
        required,
        minLengthEight,
        hasUpperCase,
        hasLowerCase,
        hasNumber,
        noFirstName: noFirstName(formFields),
        noLastName: noLastName(formFields),
        noUsername: noUsername(formFields),
        noSpecialChar
      },
      recaptchaToken: {
        hasRecaptcha: hasRecaptcha(props.requireRecaptcha)
      },
      marketingOptIn: { /*no validation - optional*/ }
    }
    const countries = ref<ICountry[]>([])

    const v$ = useVuelidate(rules, formFields)

    onMounted(async () => {
      countries.value = (await countryService?.getCountries()) ?? []
    })

    async function handleSubmit() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('submit', formFields)
    }

    function setEmail(email: string) {
      formFields.email = email
    }

    watch(() => formFields.phoneNumber.countryCode, (newVal: string) => {
      formFields.country = newVal
    })

    return {
      v$,
      titleId,
      locales,
      formFields,
      countries,
      setEmail,
      handleSubmit,
      Skin
    }
  }
})

export default CompleteRegistrationForm
