import { UserProfile } from '@/types/userProfile'
import { validate } from 'validate.js'

function IsValidUserProfile(userProfile : UserProfile) : boolean {
  return IsValidString(userProfile.firstName)
    && IsValidString(userProfile.lastName)
}

function IsValidEmail(email: string) {
  const noPlusSigns = !email.includes('+') // Workaround for Validate.js exclusion constraint not working
  return noPlusSigns && (validate({email: email}, {email: {presence: true, email: true}}) === undefined)
}

function IsValidOtp(otp: string) {
  return otp.length === 6 && !isNaN(parseInt(otp))
}

function IsValidPassword(userProfile : UserProfile, password : string) : boolean {
  const emailSplit = userProfile.emailAddress.split('@')

  return password.length >= 8
    && /[0-9]/.test(password)
    && /[A-Z]/.test(password)
    && /[a-z]/.test(password)
    && !(password.includes(emailSplit[0]) || password.includes(emailSplit[1]))
    && !password.includes(userProfile.firstName)
    && !password.includes(userProfile.lastName)
    && !password.includes(' ')
    && !password.includes('\t')
    && !password.includes('/')
    && !password.includes('\\')
    && !password.includes('\'')
    && !password.includes('"')
    && !password.includes('`')
}

function IsValidString(s : string) : boolean {
  return !!s
}

export { IsValidUserProfile, IsValidEmail, IsValidOtp, IsValidPassword, IsValidString }
