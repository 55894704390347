import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForgotPasswordForm = _resolveComponent("ForgotPasswordForm")!
  const _component_AuthTemplateWrapper = _resolveComponent("AuthTemplateWrapper")!

  return (_openBlock(), _createBlock(_component_AuthTemplateWrapper, {
    "slide-left": "",
    "slide-up": "",
    "site-skin": _ctx.$route.meta.siteSkin
  }, {
    content: _withCtx(() => [
      _createVNode(_component_ForgotPasswordForm, {
        ref: "forgotPasswordForm",
        disabled: $setup.disabled,
        "site-skin": _ctx.$route.meta.siteSkin,
        onSubmit: $setup.sendForgotPasswordEmail
      }, null, 8, ["disabled", "site-skin", "onSubmit"])
    ]),
    _: 1
  }, 8, ["site-skin"]))
}