import { createStore } from 'vuex'

export default createStore({
  state: {
    authenticated: false
  },
  mutations: {
    setAuthenticated(state) {
      state.authenticated = true
    },
    resetAuthenticated(state) {
      state.authenticated = false
    }
  },
  actions: {
  },
  modules: {
  }
})
