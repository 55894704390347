import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bed5352"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "aria-labelledby"]
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6 mb-3" }
const _hoisted_5 = { class: "col-lg-6 mb-3" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-lg-6 mb-3" }
const _hoisted_8 = { class: "col-lg-6 mb-3" }
const _hoisted_9 = {
  selected: "",
  disabled: "",
  value: ""
}
const _hoisted_10 = ["value"]
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["form", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsPhoneNumberDropdown = _resolveComponent("BsPhoneNumberDropdown")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    visible: _ctx.opened,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.opened) = $event)),
    class: "EditProfileDialog",
    modal: "",
    "close-on-escape": "",
    style: { maxWidth: '640px' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("h1", {
        id: _ctx.titleId,
        class: "EditProfileDialog__title"
      }, _toDisplayString(_ctx.locales.title), 9, _hoisted_1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn btn-outline-primary",
        type: "button",
        disabled: _ctx.disabled,
        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
      }, _toDisplayString(_ctx.locales.cancel), 9, _hoisted_11),
      _createElementVNode("button", {
        class: "btn btn-primary",
        type: "submit",
        form: _ctx.formId,
        disabled: _ctx.disabled
      }, _toDisplayString(_ctx.locales.save), 9, _hoisted_12)
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        id: _ctx.formId,
        novalidate: "",
        "aria-labelledby": _ctx.titleId,
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BsTextField, {
              modelValue: _ctx.formFields.firstName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.firstName) = $event)),
              validation: _ctx.v$.firstName,
              label: _ctx.locales.first_name,
              placeholder: _ctx.locales.first_name,
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "validation", "label", "placeholder", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BsTextField, {
              modelValue: _ctx.formFields.lastName,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formFields.lastName) = $event)),
              validation: _ctx.v$.lastName,
              label: _ctx.locales.last_name,
              placeholder: _ctx.locales.last_name,
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "validation", "label", "placeholder", "disabled"])
          ])
        ]),
        _createVNode(_component_BsTextField, {
          modelValue: _ctx.formFields.email,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formFields.email) = $event)),
          validation: _ctx.v$.email,
          class: "mb-3",
          label: _ctx.locales.email_address,
          placeholder: _ctx.locales.email_address,
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "validation", "label", "placeholder", "disabled"]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BsPhoneNumberDropdown, {
              modelValue: _ctx.formFields.phoneNumber,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formFields.phoneNumber) = $event)),
              validation: _ctx.v$.phoneNumber,
              label: _ctx.locales.phone_number,
              placeholder: _ctx.locales.phone_number,
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "validation", "label", "placeholder", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_BsSelect, {
              modelValue: _ctx.formFields.country,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formFields.country) = $event)),
              validation: _ctx.v$.country,
              label: _ctx.locales.country,
              placeholder: _ctx.locales.country,
              disabled: _ctx.disabled
            }, {
              default: _withCtx(() => [
                _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.locales.select_a_country), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: country.code,
                    value: country.code
                  }, _toDisplayString(country.name), 9, _hoisted_10))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "validation", "label", "placeholder", "disabled"])
          ])
        ])
      ], 40, _hoisted_2)
    ]),
    _: 1
  }, 8, ["visible"]))
}