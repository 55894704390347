import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!

  return (_ctx.head)
    ? (_openBlock(), _createBlock(_component_Toast, {
        key: 0,
        isOpen: _ctx.isOpen,
        "onUpdate:isOpen": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
        position: _ctx.head.position,
        type: _ctx.head.type,
        title: _ctx.head.title,
        "sub-text": _ctx.head.subText
      }, null, 8, ["isOpen", "position", "type", "title", "sub-text"]))
    : _createCommentVNode("", true)
}