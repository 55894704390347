
import { ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { createUser } from '@/api/UserApi'
import { loginAndRedirect } from '@/signin'
import { renderError } from '@/render'
import AuthTemplateWrapper from '@/components/AuthTemplateWrapper/AuthTemplateWrapper.vue'
import CompleteRegistrationForm from '@/components/CompleteRegistrationForm/CompleteRegistrationForm.vue'
import LoadingMessage from '@/components/LoadingMotivationMessage/LoadingMessage.vue'
import bcrypt from 'bcryptjs'
import type { CompleteRegistrationViewModel } from '@/components/CompleteRegistrationForm/CompleteRegistrationForm.interfaces'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { injectSessionService } from '@/providers/SessionService/SessionService'
import router from '@/router'
import { Configuration } from '@/config/config'
import { Skin, GetSignInRouteName, GetForgotPasswordRouteName } from '@/utils/SkinTools'

export default {
  components: {
    AuthTemplateWrapper,
    CompleteRegistrationForm,
    LoadingMessage
  },
  setup() {
    const sessionService = injectSessionService()
     const { showSuccessToast, showErrorToast } = useToast()
    const form = ref<InstanceType<typeof CompleteRegistrationForm>>()
    const localId = ref('')
    const route = useRoute()
    const sessionId = route.params.id as string
    const nonce = route.query.nonce as string
    const disabled = ref(false)
    const signingIn = ref(false)
    const signInRouteName = ref('')
    const forgotPasswordRouteName = ref('')

    function formatPhoneNumber(
      phoneNumber: CompleteRegistrationViewModel['phoneNumber']
    ) {
      if (phoneNumber.number) {
        return `+${phoneNumber.dialingCode} ${phoneNumber.number}`
      }
      return ''
    }

    async function registerUser(formFields: CompleteRegistrationViewModel) {
      try {
        disabled.value = true

        const passwordData = await hashPassword(formFields.password)

        await createUser(localId.value, sessionId, {
          emailAddress: formFields.email,
          firstName: formFields.firstName,
          lastName: formFields.lastName,
          phoneNumber: formatPhoneNumber(formFields.phoneNumber),
          countryCode: formFields.country,
          marketingOptIn: formFields.marketingOptIn,
          passwordHash: passwordData.passwordHash,
          passwordSalt: passwordData.passwordSalt
        }, formFields.recaptchaToken)
        showSuccessToast({
          subText: 'Your account has been successfully created.'
        })
        signingIn.value = true
        await loginAndRedirect(formFields.email, formFields.password, sessionId)
        passwordData.passwordSalt = ''
        passwordData.passwordHash = ''
      } catch (error: any) {
        if(error.response) {
          if(error.response.status === 409) {
            let errorText = error.response.data.toString()
            if (errorText.includes(' here ')) {
              let url = window.location.href
              const parser = document.createElement('a')
              parser.href = url
              const currentPage = parser.pathname.split('/')[1]
              url = url.replace(currentPage, forgotPasswordRouteName.value)
              errorText = errorText.replace(' here ', ` <a class="fw-bold text-decoration-none text-reset" href="${url}">here</a> `)
            }
            showErrorToast({
              subText: errorText
            })
            goToSignIn()
          } else {
            renderError(error.response.status, `${error.message}: ${error.response.data}`)
          }
        } else {
          renderError('400', error.message)
        }
      } finally {
        disabled.value = false
        formFields.password = ''
      }
    }

    async function hashPassword(password: string) {
      const hash = await bcrypt.hash(password, 10)
      return {
        passwordSalt: hash.substring(7, 29),
        passwordHash: hash.substring(29, hash.length)
      }
    }

    function goToSignIn() {
      router.push({
        name: signInRouteName.value,
        params: { id: sessionId },
        query: { nonce }
      })
    }

    const requireVerify = computed((): boolean => {
      if(Configuration.REQUIRE_VERIFICATION.toLowerCase() === 'true') {
        return true
      } else {
        return false
      }
    })

    const requireRecaptcha = computed((): boolean => {
      if(Configuration.REQUIRE_RECAPTCHA.toLowerCase() === 'true') {
        return true
      } else {
        return false
      }
    })

    onMounted(async () => {
      signInRouteName.value = GetSignInRouteName(route.meta.siteSkin as Skin)
      forgotPasswordRouteName.value = GetForgotPasswordRouteName(route.meta.siteSkin as Skin)
      await checkLocalStorage()
      await retrieveFromStorage()
    })

    async function checkLocalStorage() {
      try {
        await sessionService.updateSession(sessionId, nonce)
      } catch (error: any) {
        if (error.response) {
          await renderError(
            error.response.status,
            `${error.message}: ${error.response.data}`
          )
        } else {
          await renderError('400', error.message)
        }
      }
    }

    const retrieveFromStorage = async () => {
      try {
        localId.value = sessionService.getLocalId(sessionId)

        if(requireVerify.value) {
          const sessionEmail = sessionService.getUserEmail()
          form.value?.setEmail(sessionEmail)
        }
      } catch (error: any) {
        renderError('400', error.message)
      }
    }

    return {
      form,
      disabled,
      signingIn,
      registerUser,
      requireVerify,
      requireRecaptcha,
      retrieveFromStorage,
      checkLocalStorage
    }
  }
}
