import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_AuthTemplateWrapper = _resolveComponent("AuthTemplateWrapper")!
  const _component_LoadingMessage = _resolveComponent("LoadingMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.signingIn)
      ? (_openBlock(), _createBlock(_component_AuthTemplateWrapper, {
          key: 0,
          "slide-left": "",
          "slide-up": "",
          "move-banner-img-right-side": "",
          "display-links": "",
          "site-skin": _ctx.$route.meta.siteSkin,
          onForgotPw: $setup.handleForgotPw,
          onForgotUn: $setup.handleForgotUn
        }, {
          content: _withCtx(() => [
            _createVNode(_component_LoginForm, {
              disabled: $setup.disabled,
              "hide-register": $setup.hideRegister,
              "site-skin": _ctx.$route.meta.siteSkin,
              onRegister: $setup.handleRegister,
              onSubmit: $setup.handleSubmit
            }, null, 8, ["disabled", "hide-register", "site-skin", "onRegister", "onSubmit"])
          ]),
          _: 1
        }, 8, ["site-skin", "onForgotPw", "onForgotUn"]))
      : _createCommentVNode("", true),
    ($setup.signingIn)
      ? (_openBlock(), _createBlock(_component_LoadingMessage, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}