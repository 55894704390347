import { watch, Ref, onUnmounted } from 'vue'

export const useOutsideWheel = (toggle: Ref<boolean>, elementRef: Ref<HTMLElement | undefined>, callback: () => void) => {
  watch(toggle, (newState) => {
    if (newState) {
      window.addEventListener('wheel', handleWheel)
    } else {
      window.removeEventListener('wheel', handleWheel)
    }
  })

  onUnmounted(() => {
    window.removeEventListener('wheel', handleWheel)
  })

  function handleWheel(event: WheelEvent) {
    if (!event.composedPath().includes(elementRef.value as EventTarget)) {
      callback()
    }
  }
}
