import axios from 'axios'
import { GetUrlString, GetServicesUrlString } from './ApiBase'
import { MyAccountProfile, UserProfile } from '@/types/userProfile'
import { ValidateOtpResponse } from '@/types/response'

async function sendOtp(localId: string, sessionId: string, email: string) {
  return await axios.post(GetUrlString('anon/verifications'), {email, localId, sessionId})
}

async function validateOtp(localId: string, sessionId: string, email: string, code: string): Promise<ValidateOtpResponse> {
  const response = await axios.post(
    GetUrlString('anon/verifications/otp'), {email, code, localId, sessionId}
  )

  return response.data as ValidateOtpResponse
}

async function createUser(localId: string, sessionId: string, userProfile: UserProfile, recaptchaToken: string) {
  return await axios.post(GetUrlString('anon/users'), {localId, sessionId, userProfile, recaptchaToken})
}

async function updateUser(myAccountProfile: MyAccountProfile) {
  try {
    return await axios.put(GetUrlString('users/self'),
     {
       firstName: myAccountProfile.firstName,
       lastName: myAccountProfile.lastName,
       phoneNumber: myAccountProfile.phoneNumber,
       dialingCode: myAccountProfile.dialingCode,
       countryCode: myAccountProfile.countryCode,
       emailAddress: myAccountProfile.email
      })
  }
  catch(error: any) {
    throw new Error()
  }
}

async function forgotPassword(localId: string, sessionId: string, login: string) {
  return await axios.post(GetUrlString('anon/forgotten-password-requests'), {login, localId, sessionId})
}

async function getMotivationalQuote() {
  return await axios.create().get(GetServicesUrlString('content/cms/inspirational'))
}

async function reactivateUser(localId: string, sessionId: string, login: string) {
  return await axios.post(GetUrlString('anon/reactivate-user-requests'), {login, localId, sessionId})
}

async function forgotUsername(localId: string, sessionId: string, email: string) {
  return await axios.post(GetUrlString('anon/forgotten-username-requests'), {email, localId, sessionId})
}

export {
  sendOtp,
  validateOtp,
  createUser,
  updateUser,
  forgotPassword,
  getMotivationalQuote,
  reactivateUser,
  forgotUsername
}
