import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55f5f580"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "value", "checked", "aria-describedby", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "BsCheckbox__label d-flex flex-wrap gap-1" }
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "BsCheckbox form-check mx-0 align-items-start"
  }, [
    _createElementVNode("input", {
      id: _ctx.inputId,
      class: _normalizeClass(["form-check-input", {
        'is-invalid': _ctx.dirty && _ctx.invalid
      }]),
      type: "checkbox",
      value: _ctx.modelValue,
      checked: _ctx.modelValue,
      "aria-describedby": _ctx.invalidMessageId,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
    }, null, 42, _hoisted_2),
    (_ctx.$slots.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-check-label",
          for: _ctx.inputId
        }, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "label", {}, undefined, true)
          ])
        ], 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.dirty)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: _ctx.invalidMessageId,
          class: "invalid-feedback"
        }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_5))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}