import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompleteRegistrationForm = _resolveComponent("CompleteRegistrationForm")!
  const _component_AuthTemplateWrapper = _resolveComponent("AuthTemplateWrapper")!
  const _component_LoadingMessage = _resolveComponent("LoadingMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.signingIn)
      ? (_openBlock(), _createBlock(_component_AuthTemplateWrapper, {
          key: 0,
          "slide-left": "",
          "slide-up": "",
          "site-skin": _ctx.$route.meta.siteSkin
        }, {
          content: _withCtx(() => [
            _createVNode(_component_CompleteRegistrationForm, {
              ref: "form",
              disabled: $setup.disabled,
              "require-verify": $setup.requireVerify,
              "require-recaptcha": $setup.requireRecaptcha,
              "site-skin": _ctx.$route.meta.siteSkin,
              onSubmit: $setup.registerUser
            }, null, 8, ["disabled", "require-verify", "require-recaptcha", "site-skin", "onSubmit"])
          ]),
          _: 1
        }, 8, ["site-skin"]))
      : _createCommentVNode("", true),
    ($setup.signingIn)
      ? (_openBlock(), _createBlock(_component_LoadingMessage, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}