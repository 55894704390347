
import { ref, onMounted } from 'vue'
import ForgotPasswordForm from '@/components/ForgotPasswordForm/ForgotPasswordForm.vue'
import AuthTemplateWrapper from '@/components/AuthTemplateWrapper/AuthTemplateWrapper.vue'
import { useRoute } from 'vue-router'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'
import { IsValidEmail } from '@/types/validation/userValidation'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { renderError } from '@/render'
import { forgotPassword } from '@/api/UserApi'

export default {
   components: {
    AuthTemplateWrapper,
    ForgotPasswordForm
  },
  setup() {
    const sessionService = injectSessionService()
    const route = useRoute()
    const sessionId = route.params.id as string
    const localId = ref('')
    const disabled = ref(false)
    const { showSuccessToast } = useToast()

    async function sendForgotPasswordEmail(login: string) {
      disabled.value = true

      try {
        await forgotPassword(localId.value, sessionId, login)
        const loginType = IsValidEmail(login) ? 'email address' : 'username'

        showSuccessToast({
          subText:
            `If an account exists with this ${loginType}, you will receive an email shortly with instructions to reset your password.`
        })
      } catch (error: any) {
        renderError(error.response.status, `${error.message}: ${error.response.data}`)
      } finally {
        disabled.value = false
      }
    }

    onMounted(() => {
      localId.value = sessionService.getLocalId(sessionId)
    })

    return{
      disabled,
      sendForgotPasswordEmail
    }
  }
}
