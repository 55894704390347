import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b19b628"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "data-invalid", "data-disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_5 = ["id", "type", "placeholder", "aria-describedby", "value", "required", "disabled", "maxlength"]
const _hoisted_6 = {
  key: 1,
  class: "input-group-append"
}
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = {
  key: 0,
  role: "tooltip",
  class: "BsTextField__tooltip"
}
const _hoisted_9 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "BsTextField form-control-helper"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.inputId,
          class: "BsTextField__label form-label",
          "data-invalid": _ctx.dirty && _ctx.invalid,
          "data-disabled": _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.prepend)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.prepend), 1))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        id: _ctx.inputId,
        ref: "input",
        class: _normalizeClass(["BsTextField__input form-control", {
          'is-invalid': _ctx.dirty && _ctx.invalid
        }]),
        type: _ctx.showPassword ? 'text' : _ctx.type,
        placeholder: _ctx.placeholder,
        "aria-describedby": _ctx.invalidMessage && _ctx.invalidMessageId,
        value: _ctx.modelValue,
        required: _ctx.showRequiredAttr,
        disabled: _ctx.disabled,
        maxlength: _ctx.maxlength,
        "aria-invalid": "false",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInputFocus && _ctx.handleInputFocus(...args))),
        onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInputBlur && _ctx.handleInputBlur(...args))),
        onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleInputKeyDown && _ctx.handleInputKeyDown(...args)))
      }, null, 42, _hoisted_5),
      (_ctx.type === 'password' && _ctx.togglePassword)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
            _createElementVNode("button", {
              class: _normalizeClass(["btn btn-outline-secondary bg-white password-toggle-btn", _ctx.dirty && _ctx.invalid ? 'is-invalid' : '']),
              type: "button",
              "aria-label": 
            _ctx.showPassword
              ? _ctx.locales.aria_label_password_visible
              : _ctx.locales.aria_label_password_hidden
          ,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleShowPassword()))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["bi eye-icon", _ctx.showPassword ? 'bi-eye-slash' : 'bi-eye'])
              }, null, 2)
            ], 10, _hoisted_7)
          ]))
        : _createCommentVNode("", true),
      (_ctx.existTooltip)
        ? (_openBlock(), _createBlock(_Transition, {
            key: 2,
            name: "fade"
          }, {
            default: _withCtx(() => [
              (_ctx.focused && !_ctx.dismissTooltip)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _renderSlot(_ctx.$slots, "tooltip", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            id: _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_9))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}