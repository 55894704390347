
import ToastQueue from '@/components/ToastQueue/ToastQueue.vue'
import { autoProvideCountryService } from '@/providers/CountryService/CountryService'
import { autoProvideSessionService } from '@/providers/SessionService/SessionService'
import { autoProvideToastQueueStore } from '@/components/ToastQueue/ToastQueue.store'

export default {
  components: { ToastQueue },
  setup() {
    autoProvideCountryService()
    autoProvideSessionService()
    autoProvideToastQueueStore()
    return {}
  }
}
