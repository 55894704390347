
import { ref, onMounted } from 'vue'
import { useRoute, RouteLocationRaw } from 'vue-router'
import router from '@/router'
import { sendOtp } from '@/api/UserApi'
import { logErrorMessage } from '@/logging'
import { renderError } from '@/render'
import AuthTemplateWrapper from '@/components/AuthTemplateWrapper/AuthTemplateWrapper.vue'
import RegisterEmailForm from '@/components/RegisterEmailForm/RegisterEmailForm.vue'
import { useToast } from '@/components/ToastQueue/ToastQueue.utils'
import { injectSessionService } from '@/providers/SessionService/SessionService.utils'

export default {
  components: {
    AuthTemplateWrapper,
    RegisterEmailForm
  },
  setup() {
    const sessionService = injectSessionService()
    const { showErrorToast } = useToast()
    const route = useRoute()
    const sessionId = route.params.id as string
    const nonce = route.query.nonce as string
    const localId = ref('')
    const disabled = ref(false)
    const loginRoute = ref<RouteLocationRaw>({
      name: 'SignIn',
      params: { id: sessionId },
      query: { nonce }
    })

    async function generateOtp(email: string) {
      disabled.value = true
      try {
        await sendOtp(localId.value, sessionId, email)
        sessionService.setUserEmail(email)
        await router.push({
          name: 'Verify',
          params: { id: sessionId },
          query: { nonce }
        })
      } catch (error: any) {
        const errorText = error.response.data.toLowerCase()
        if(errorText.includes('email')) {
          logErrorMessage(`${error.message}: ${error.response.data}`)
          showErrorToast({
            subText:
              'There was an issue sending your verification code. Please ensure your email is valid and try again.'
          })
        } else {
          renderError(error.response.status, `${error.message}: ${error.response.data}`)
        }
      } finally {
        disabled.value = false
      }
    }

    function goToSignIn() {
      router.push({
          name: 'SignIn',
          params: { id: sessionId },
          query: { nonce }
        })
    }

    onMounted(async () => {
      localId.value = sessionService.getLocalId(sessionId)
    })

    return {
      loginRoute,
      disabled,
      generateOtp,
      goToSignIn
    }
  }
}
