import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-674e5882"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "heading" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuccessTick = _resolveComponent("SuccessTick")!
  const _component_ExclamationCircle = _resolveComponent("ExclamationCircle")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass('toast-main-segment' + ' ' + _ctx.position + ' ' + _ctx.type),
              role: "alert",
              "aria-live": "assertive"
            }, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", null, [
                    (_ctx.type === 'success')
                      ? (_openBlock(), _createBlock(_component_SuccessTick, { key: 0 }))
                      : (_openBlock(), _createBlock(_component_ExclamationCircle, { key: 1 }))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.title), 1),
                    _createElementVNode("p", {
                      class: "sub-heading",
                      innerHTML: _ctx.subText
                    }, null, 8, _hoisted_3)
                  ])
                ])
              ], true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}