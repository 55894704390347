import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7788974d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-selected", "focused"]
const _hoisted_2 = { class: "BsPhoneNumberDropdownItem__flag" }
const _hoisted_3 = { class: "BsPhoneNumberDropdownItem__code" }
const _hoisted_4 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryFlag = _resolveComponent("CountryFlag")!

  return (_openBlock(), _createElementBlock("li", {
    role: "option",
    class: _normalizeClass(["BsPhoneNumberDropdownItem dropdown-item", { active: _ctx.selected }]),
    "aria-selected": _ctx.selected,
    focused: _ctx.focused
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CountryFlag, {
        country: _ctx.country.code,
        size: "normal"
      }, null, 8, ["country"])
    ]),
    _createElementVNode("span", _hoisted_3, " (+" + _toDisplayString(_ctx.country.dialingCode) + ") ", 1),
    _createElementVNode("span", {
      class: "BsPhoneNumberDropdownItem__name",
      title: _ctx.country.name
    }, _toDisplayString(_ctx.country.name), 9, _hoisted_4)
  ], 10, _hoisted_1))
}