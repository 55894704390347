
import { defineComponent, computed } from 'vue'
import locales from './AuthTemplateWrapper.locales.en.json'
import { Skin } from '@/utils/SkinTools'

export default defineComponent({
  name: 'AuthTemplateWrapper',
  props: {
    moveBannerImgRightSide: {
      type: Boolean,
      default: false
    },
    slideLeft: {
      type: Boolean,
      default: false
    },
    slideRight: {
      type: Boolean,
      default: false
    },
    slideUp: {
      type: Boolean,
      default: false
    },
    displayLinks: {
      type: Boolean,
      default: false
    },
    siteSkin: {
      type: String,
      default: 'barbri'
    }
  },
  emits: ['forgot-pw', 'forgot-un'],
  setup(props, { emit }) {
    function handleForgotPw() {
      emit('forgot-pw')
    }

    function handleForgotUn() {
      emit('forgot-un')
    }

    const useSkinLogo = computed((): boolean => {
      return props.siteSkin === Skin.pe || props.siteSkin === Skin.powerscore
    })

    return {
      locales,
      Skin,
      handleForgotPw,
      handleForgotUn,
      useSkinLogo
    }
  }
})
