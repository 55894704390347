import { inject, provide } from 'vue'
import {
  IToastItemArgsByType,
  IToastQueueStore,
  IToastQueueStoreSymbol
} from '@/components/ToastQueue/ToastQueue.interfaces'

export function injectToastQueueStore() {
  const instance = inject<IToastQueueStore>(IToastQueueStoreSymbol)
  if (!instance) {
    throw new Error('There is no provided instance for IToastQueueStore')
  }
  return instance
}

export function provideToastQueueStore(store: IToastQueueStore) {
  return provide(IToastQueueStoreSymbol, store)
}

export function useToast(instance = injectToastQueueStore()) {
  function showSuccessToast(item: IToastItemArgsByType) {
    instance?.actions.addSuccess(item)
  }

  function showErrorToast(item: IToastItemArgsByType) {
    instance?.actions.addError(item)
  }

  return {
    showSuccessToast,
    showErrorToast
  }
}
