import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-431ce5c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "email-input-segment" }
const _hoisted_2 = { class: "w-100 text-center" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock("form", {
    class: "forgot-password-form-segment",
    "aria-labelledby": "forgot-password-heading",
    novalidate: "",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.verifyLogin && _ctx.verifyLogin(...args)), ["prevent"]))
  }, [
    _createElementVNode("p", {
      id: "forgot-password-heading",
      class: _normalizeClass(["text-center", { [_ctx.siteSkin + '-text']: true }])
    }, _toDisplayString(_ctx.locales.title), 3),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BsTextField, {
        id: "forgot-password-login",
        modelValue: _ctx.formFields.login,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.login) = $event)),
        class: "mb-3",
        label: _ctx.locales.login,
        validation: _ctx.v$.login,
        disabled: _ctx.disabled,
        "auto-trim": ""
      }, null, 8, ["modelValue", "label", "validation", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        type: "submit",
        disabled: _ctx.disabled
      }, _toDisplayString(_ctx.locales.submit), 9, _hoisted_3)
    ])
  ], 32))
}