
import { defineComponent, reactive} from 'vue'
import BsTextField from '../BsTextField/BsTextField.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import locales from './LoginForm.locales.en.json'
import { LoginFormViewModel } from './LoginForm.interfaces'
import { Skin } from '@/utils/SkinTools'

export const LoginForm = defineComponent({
  name: 'LoginForm',
  components: { BsTextField },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    hideRegister: {
      type: Boolean,
      default: false
    },
    siteSkin: {
      type: String,
      default: 'barbri'
    }
  },
  emits: ['register', 'submit'],
  setup(props, { emit }) {
    const formFields = reactive<LoginFormViewModel>({
      email: '',
      password: ''
    })

    const rules = {
      email: { required },
      password: { required }
    }

    const v$ = useVuelidate(rules, formFields)

    function handleSubmit() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('submit', formFields)
    }

    function handleRegister() {
      emit('register')
    }

    function resetPassword() {
      formFields.password = ''
    }

    return {
      v$,
      locales,
      formFields,
      Skin,
      handleSubmit,
      handleRegister,
      resetPassword
    }
  }
})

export default LoginForm
