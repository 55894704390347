import { oktaAuth } from '@/okta'

async function loginAndRedirect (email: string, password: string, sessionId: string) {
  const transaction = await oktaAuth.signInWithCredentials({
    username: email,
    password: password
  })

  password = ''

  if (transaction.status === 'SUCCESS') {
    oktaAuth.token.getWithRedirect({
      state: JSON.stringify({sessionId: sessionId}),
      sessionToken: transaction.sessionToken
    })
  }
}

export { loginAndRedirect }
