
import { defineComponent, PropType, computed, unref, ref } from 'vue'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementId } from '@/utils/VueTools'
import locales from './BsTextField.locales.en.json'

const BsTextField = defineComponent({
  name: 'BsTextField',
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('BsTextField')
    },
    modelValue: String,
    label: String,
    type: String,
    placeholder: String,
    disabled: Boolean,
    validation: Object as PropType<Validation>,
    prepend: String,
    append: String,
    maxlength: String,
    autoTrim: Boolean,
    togglePassword: Boolean
  },
  emits: ['update:modelValue'],
  setup(props, { emit, slots }) {
    const input = ref<HTMLInputElement>()
    const showPassword = ref<boolean>(false)
    const focused = ref<boolean>(false)
    const existTooltip = computed<boolean>(() => Boolean(slots.tooltip?.()))
    const dismissTooltip = ref<boolean>(false)
    const inputId = computed(() => props.id + '__input')
    const invalidMessageId = computed(() => props.id + '__invalid_message')

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const showRequiredAttr = computed<boolean>(() => {
      return props.validation
        ? Object.keys(props.validation).includes('required')
        : false
    })

    function handleChange(event: InputEvent) {
      if (props.autoTrim) {
        const target = event.target as HTMLInputElement
        emit('update:modelValue', target.value.trim())
      }
    }

    function handleInput(event: InputEvent) {
      const target = event.target as HTMLInputElement
      emit('update:modelValue', target.value)
    }

    function toggleShowPassword() {
      showPassword.value = !showPassword.value
    }

    function handleInputFocus() {
      focused.value = true
    }

    function handleInputBlur() {
      focused.value = false
      dismissTooltip.value = false
    }

    function handleInputKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape' || event.key === 'esc') {
        dismissTooltip.value = true
      }
    }

    return {
      input,
      inputId,
      invalidMessageId,
      dirty,
      invalid,
      focused,
      existTooltip,
      dismissTooltip,
      invalidMessage,
      showRequiredAttr,
      locales,
      showPassword,
      handleChange,
      handleInput,
      toggleShowPassword,
      handleInputFocus,
      handleInputBlur,
      handleInputKeyDown
    }
  }
})

export default BsTextField
