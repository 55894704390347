
import { onMounted } from 'vue'
import { Configuration } from '@/config/config'

export default {
  setup() {
    onMounted(async () => {
      location.assign(Configuration.POWERSCORE_REDIRECT)
    })
  }
}
